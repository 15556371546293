import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sandbox-error',
  templateUrl: './sandbox-error.component.html',
  styleUrls: ['./sandbox-error.component.css'],
})
export class SandboxErrorComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
