<footer *ngIf="!enableUiRebranding" class="footer front">
    <div class="container" style="width: 1200px; height: 200px; padding-top: 115px;">
        <section id="block-menu-menu-footer" class="block block-menu clearfix">
          <ul class="menu nav">
            <li class="first leaf li-margin-right">
              <a href="https://www.dexcom.com/" class="pull-right" target="_blank">Dexcom</a>
            </li>
            <li class="leaf pull-right li-margin-right">
              <a href="https://status.developer.dexcom.com/">Status Page</a>
            </li>
            <li class="leaf pull-right li-margin-right">
              <a href="https://careers.dexcom.com/" class="pull-right" target="_blank">Careers</a>
            </li>
            <li class="leaf pull-right li-margin-right">
              <a routerLink="support/compliance" class="pull-right">Compliance</a>
            </li>
            <li class="leaf pull-right li-margin-right">
              <a
                href="https://www.dexcom.com/linked/documentservice/TermsOfUse"
                class="pull-right"
                target="_blank"
                >Terms of Use</a
              >
            </li>
            <li class="leaf pull-right li-margin-right">
              <a
                href="https://www.dexcom.com/linked/documentservice/PrivacyPolicy"
                class="pull-right"
                target="_blank"
                >Privacy Policy</a
              >
            </li>
            <li class="last leaf">
              <a href="https://www.dexcom.com/safety-information" target="_blank"
                >Safety Information</a
              >
            </li>
          </ul>
        </section>
        <!-- /.block -->
    </div>
    <section
          id="block-copyright-block-copyright-block"
          class="block block-copyright-block clearfix"
          style="height: 50px;"
        >
          <p style="font-size: 14px;">
            <span style="font-weight: 600; font-size: 0.925em;"
              >©2016-2022 Dexcom, Inc. All rights reserved. This product is covered by U.S.
              patent.</span
            >
          </p>
    </section>
</footer>
<footer *ngIf="enableUiRebranding" class="rebranded-footer front">
  <div class="container" style="width: 100%; height: 150px; padding-top: 68px; margin-left: 55px;">
    <section id="block-menu-menu-footer" class="block block-menu clearfix">
      <ul id="rebranded-footer" class="menu nav">
        <li class="first leaf li-margin-right">
          <a href="https://www.dexcom.com/" class="pull-right" target="_blank">Dexcom</a>
        </li>
        <li class="leaf pull-right li-margin-right">
          <a href="https://status.developer.dexcom.com/">Status Page</a>
        </li>
        <li class="leaf pull-right li-margin-right">
          <a href="https://careers.dexcom.com/" class="pull-right" target="_blank">Careers</a>
        </li>
        <li class="leaf pull-right li-margin-right">
          <a routerLink="support/compliance" class="pull-right">Compliance</a>
        </li>
        <li class="leaf pull-right li-margin-right">
          <a
            href="https://www.dexcom.com/linked/documentservice/TermsOfUse"
            class="pull-right"
            target="_blank"
            >Terms of Use</a
          >
        </li>
        <li class="leaf pull-right li-margin-right">
          <a
            href="https://www.dexcom.com/linked/documentservice/PrivacyPolicy"
            class="pull-right"
            target="_blank"
            >Privacy Policy</a
          >
        </li>
        <li class="leaf pull-right li-margin-right">
          <a
            href="https://www.dexcom.com/safety-information"
            class="pull-right"
            target="_blank"
            >Safety Information</a
          >
        </li>
        <li class="last leaf">
          <a href="https://www.dexcom.com/partnerships/integrations-and-compatibility" target="_blank"
            >Made For Connections</a
          >
        </li>
      </ul>
    </section>
  </div>
  <div class="container" style="width: 74%; padding-top: 0; margin-left: 55px;">
    <section id="block-copyright-block-copyright-block" class="block block-menu clearfix" style="height: 100px;">
      <p>Dexcom, Dexcom Clarity, Dexcom Follow, Dexcom One, Dexcom Share, and any related logos and design marks are either registered trademarks or trademarks of Dexcom, Inc. in the United States and/or other countries. ©2023 Dexcom, Inc. All rights reserved.</p>
      <p>LBL-1003504 REV001</p>
    </section>
  </div>
</footer>
