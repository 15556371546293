<header [attr.id]="enableUiRebranding?'rebrandedId':'navbar'" role="banner" class="navbar navbar-fixed-top navbar-inverse">
  <div class="container">
    <div class="navbar-header">
      <a class="logo navbar-btn pull-left" routerLink="/home" title="Home">
        <img *ngIf="!enableUiRebranding" src="../assets/image/developer_logo.png" alt="Dexcom API" />
        <img *ngIf="enableUiRebranding" src="../assets/image/developer_logo1.png" alt="Dexcom API" />
      </a>
    </div>
    <div class="navbar-menus" *ngIf="!isSandbox">
      <nav role="navigation" >
        <ul [class.rebranded-menu]="enableUiRebranding" class="menu nav navbar-nav full-menu primary-nav hidden-xs">
          <li class="first leaf">
            <a href="/docs" routerLinkActive="active-trail active">Docs</a>
            <hr />
          </li>
          <li class="leaf">
            <a routerLink="/support" routerLinkActive="active-trail active">Support</a>
          </li>
          <li class="last leaf">
            <a routerLink="/gallery" routerLinkActive="active-trail active">Gallery</a>
          </li>
        </ul>
        <ul [class.rebranded-menu]="enableUiRebranding" class="menu nav navbar-nav mobile-menu primary-nav visible-xs">
          <li class="first leaf">
            <a href="/docs">Docs</a>
          </li>
          <li class="leaf">
            <a routerLink="/support" routerLinkActive="active-trail active">Support</a>
          </li>
          <li class="last leaf">
            <a routerLink="/gallery" routerLinkActive="active-trail active">Gallery</a>
          </li>
        </ul>
        <app-nav-bar-auth-button></app-nav-bar-auth-button>
      </nav>
    </div>
  </div>
</header>
