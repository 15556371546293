<div class="page-content">
  <div class="main-container container">
    <div class="row">
      <div class="col"></div>
      <div class="col-6">
        <p class="style">Select the Sandbox user to authenticate:</p>
        <form (ngSubmit)="onSubmit()" #heroForm="ngForm">
          <select class="user-selection" name="username-text-input" [(ngModel)]="selectedUser">
            <option value="">(Select One)</option>
            <option value="SandboxUser1">SandboxUser1</option>
            <option value="SandboxUser2">SandboxUser2</option>
            <option value="SandboxUser3">SandboxUser3</option>
            <option value="SandboxUser4">SandboxUser4</option>
            <option value="SandboxUser5">SandboxUser5</option>
            <option value="SandboxUser6">SandboxUser6</option>
            <option value="SandboxUser7">SandboxUser7</option>
          </select>
          <p>
            <button class="btn" type="submit">Login</button>
          </p>
          <div class="setWidth">
            <p><strong>Note:</strong></p>
            <p>
              Details on the data profiles for each of the Sandbox users is available in the Sandbox
              Data section.
            </p>
            <p>
              This login flow is unique to the
              <a routerLink="/docs/sandbox-data" target="_blank">Sandbox Data</a>
              Environment. When accessing the Production Data Environment, a different
              authentication and authorization process will be presented. See the
              <a routerLink="/docs/authentication" target="_blank">Authentication </a>section for
              details.
            </p>
          </div>
        </form>
      </div>
      <div class="col"></div>
    </div>
  </div>
</div>
