// @ts-nocheck
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

type EnvName = 'local' | 'dev' | 'acceptance' | 'prod';

export const environment = {
  name: (window?.env?.ENV_NAME || 'local') as EnvName,
  apiUrl: window?.env?.DEVELOPER_API_URL || 'http://localhost:8080',
  dexcomApiUrl: window?.env?.PARTNER_API_BASE_URL || 'https://component.api.us.dexcomdev.com',
  devApiUrl: window?.env?.DEVELOPER_API_URL || 'https://developer-api-dot-g5-dexcom-dev-us-5.uc.r.appspot.com',
  recaptchaSiteKey:
    window?.env?.DEVELOPER_PORTAL_RECAPTCHA_SITEKEY || '6LfcuCYhAAAAAAt-HsEs0LO3BGKzO2cN2AoVli-u',
  adminClaimActionsEnabled: window?.env?.DEVELOPER_PORTAL_ADMIN_CLAIMS_ACTIONS_ENABLED || 'true',
  invalidLogBackInUrls: window?.env?.DEVELOPER_PORTAL_INVALID_LOG_BACK_IN_URLS || '/user/login,/admin/users/,/user/register,/user/forgot-password,/home',
  partnerUpdateAppNameEnabled: window?.env?.DEVELOPER_PORTAL_UPDATE_APP_NAME_ENABLED || 'false',
  nonAdminRedirectURIDomainsRestriction: window?.env?.DEVELOPER_PORTAL_NON_ADMIN_REDIRECTURI_DOMAINS_RESTRICTION || 'dexcom.com,dexcom.eu,dexcom.jp,TryTerra,TryRook,TryVital',
  individualAccessStatus: window?.env?.DEVELOPER_PORTAL_INDIVIDUAL_ACCESS_VALUE || 'individual',
  sandboxAccessStatus: window?.env?.DEVELOPER_PORTAL_SANDBOX_ACCESS_VALUE || 'sandbox',
  fullAccessStatus: window?.env?.DEVELOPER_PORTAL_FULL_ACCESS_VALUE || '1',
  enableUiRebranding: window?.env?.DEVELOPER_PORTAL_ENABLE_UI_REBRANDING || 'true',
  enablePIA: window?.env?.DEVELOPER_PORTAL_ENABLE_PIA || 'false'
  };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
