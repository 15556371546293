<style>
  @import url('../../assets/css/dexcom_app_gallery.css');
</style>

<div class="page-content">
  <div class="main-container container">
    <div class="row">
      <section class="col-sm-12">
        <!-- Title Prefix -->
        <!-- Title -->
        <h1 class="page-title">App Gallery</h1>
        <!-- SubTitle -->
        <!-- Title Suffix -->

        <div class="region region-content">
          <section id="block-system-main" class="block block-system pull-right clearfix">
            <div
              class="view view-app-gallery view-id-app_gallery view-display-id-gallery view-dom-id-d65f3014f4be000c37c8b1b99b355859 app-gallery-four-col">
              <div *ngIf="!enableUiRebranding" class="view-header">
                <div class="header-title">
                  <h1>Dexcom App Gallery</h1>
                </div>
              </div>

              <div *ngIf="enableUiRebranding" class="gallery-header">
                <h1 class="default-font h1-font">Dexcom CGM empowers its users and healthcare providers to seamlessly connect with digital health apps.</h1>
              </div>

              <div *ngIf="enableUiRebranding" class="gallery-sub-header">
                <h3 class="default-font h3-font">Users authorize third-party apps to connect to their data through the Dexcom cloud.<sup>*</sup></h3>
              </div>

              <div class="view-content">
                <div class="views-row views-row-1 views-row-odd views-row-first">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-116 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="116"><img id="icon_1bios" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/icon_spaced2.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">1bios</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          1bios is a health management, monitoring, and engagement tool for your
                          insurer or care provider to support and reward your better health.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a (click)="toggleAppModal($event)" id="link_1bios"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-116 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="116">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-2 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-143 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="143"><img id="icon_blue_star" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/bluestar_logo_120px.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">BlueStar</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>BlueStar®, an in-app coach for people living with diabetes.</p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_blue_star" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-143 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="143">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-3 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_care_connection" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/care_connection-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Care Connection</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Care Connection enables a practice or facility of any size to deploy a successful Remote Patient Monitoring system that integrates directly with Dexcom. We offer support through a variety of dashboards and reports to make both clinical monitoring and billing as seamless and simple as possible.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_care_connection" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-4 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_care_simple" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/care_simple-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">CareSimple</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Hospital-at-Home (HaH), Remote Patient Monitoring (RPM), Chronic Care Management (CCM) and Remote Therapeutic Monitoring (RTM) Made Simple
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_care_simple" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-5 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_carium" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/carium-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Carium</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Carium is a convenient, stress-free way to manage your healthcare journey from the comfort and safety of home.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_carium" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-6 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-114 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="114"><img id="icon_center_health" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/center_health-02.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Center Health</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          An easier way to live with diabetes combining simple logging, collaboration, and your very own personal assistant!
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_center_health" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-114 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="114">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-7 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_codex" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/codex-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Codex Health</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Codex Health develops clinical intelligence solutions for healthcare providers to bring the power of data-driven decision making into healthcare workflows.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_codex" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-8 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_cori" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/cori-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Cori App</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Cori is the Artificial Intelligence driven Health Coach for diabetes that guides you in your daily life so you don't give up. 
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_cori" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-9 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_cronometer" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/cronometer-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Cronometer</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Cronometer is one of the most accurate, comprehensive nutrition & health tracking app on earth.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_cronometer" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-10 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_diabeto_log" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/diabeto_log-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Diabeto Log</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          The modern log book you need to keep your diabetes in check. Track your blood glucose, insulin and meds intakes with the least possible effort.  
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_diabeto_log" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-11 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-152 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="152"><img id="icon_dreamed" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/dreamed_circle.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">DreaMed Advisor Pro</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          DreaMed Advisor Pro is a cloud-based digital solution generating insulin
                          delivery recommendations.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_dreamed" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-152 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="152">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-12 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_enhance_d" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/enhance_d-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Enhance-d</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Integrate your glucose, insulin, exercise, nutrition and sleep data to help you and your doctor better manage your diabetes.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_enhance_d" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-13 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_evidation" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/evidation-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Evidation</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Learn more about your health and earn rewards with Evidation.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_evidation" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-14 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-147 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="147"><img id="icon_fitabase" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/circlelogo_120_120.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Fitabase</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Fitabase is a data management platform that allows researchers to capture
                          and analyze high-resolution data from wearable devices.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_fitabase" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-147 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="147">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-15 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_garmin" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/garmin-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Garmin</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Garmin provides free apps so you can see your glucose levels at a glance on your smartwatch and cycling computers.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_garmin" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-16 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_genie_md" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/genie_md-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">GenieMD</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          GenieMD is an award-winning, global provider of modular, scalable and customizable virtual care platform, inclusive of: Telehealth, RPM, RTM, CCM/PCM. 
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_genie_md" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-17 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-115 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="115"><img id="icon_glooko" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/glooko-logo-large.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Glooko</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Mobile &amp; Web app that combines glucose, pump, exercise, food &amp;
                          medications and provides insights for daily decision making.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_glooko" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-115 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="115">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-18 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-144 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="144"><img id="icon_glucose_buddy" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/glucose_buddy-02.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Glucose Buddy</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Glucose Buddy with Meal IQ allows you to track your diet with food photos and provides feedback on how the foods you eat impact your blood glucose.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_glucose_buddy" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-144 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="144">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-19 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-148 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="148"><img id="icon_glucose_zone" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/gzlogo-120px.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">GlucoseZone</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          GlucoseZone is a digital therapeutic designed to help you reach your
                          diabetes and exercise goals.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_glucose_zone" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-148 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="148">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-20 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_guava" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/guava-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Guava Health</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Guava helps you track and see trends across your health devices, lab tests, symptom and med tracking, and more, all in one place. 
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_guava" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-21 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_happy_bob" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/happy_bob-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Happy Bob</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Happy Bob is a personal digital health companion helping you achieve better glycemic control* while reducing the stress that comes with diabetes data overload.  
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_happy_bob" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-22 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_heads_up" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/heads_up-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Heads Up</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Heads Up is a remote health monitoring platform that unifies clinical and lifestyle data for personalized health insights.  
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_heads_up" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-23 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_health_wealth_safe" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/health_wealth_safe-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Health Wealth Safe</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Our app lets you track your health from your phone, import health data from multiple sources and share it instantly with your Doctor.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_health_wealth_safe" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-24 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_healthie" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/healthie-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Healthie</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          HIPAA & SOC-2 compliant scheduling, EMR, and client engagement platform designed for digital health companies.  
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_healthie" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-25 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_healthy_connect" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/healthy_connect-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Healthy Connect</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          HealthyConnect by RCP pairs Patients managing diabetes with dedicated Nurse Coaches to assist in their health journey
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_healthy_connect" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-26 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_heart_cloud" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/heart_cloud-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">HeartCloud</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Our platform helps contextualize glucose levels with readings from other digital health devices, including daily, weekly, and monthly changes in sleep and exercise times, blood pressure, weight, and heart rate.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_heart_cloud" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-27 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_hearty" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/hearty-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Hearty</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Hearty is a health, wellness, and longevity platform
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_hearty" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-28 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-149 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="149"><img id="icon_inpen" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/inpen-02.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">InPen</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          InPen™ smart insulin pen
                          InPen, by Medtronic, is the first and only FDA-cleared smart pen for insulin delivery.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_inpen" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-149 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="149">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-29 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_insulclock" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/insulclock-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Insulclock</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          INSULCLOCK 360 is a medical device software developed to facilitate the management of diabetes by healthcare professionals and patients.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_insulclock" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-30 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_kura_care" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/kura_care-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Kura Care</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Kura Care provides turnkey remote care management solutions to help drive patient's adherence to their clinical care plans. 
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_kura_care" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-31 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_life_omic" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/life_omic-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">LifeOmic</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          The LifeOmic mobile app connects doctors and researchers and their users.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_life_omic" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-32 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_meteda" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/logo-meteda-120x120px.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Meteda</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          METEDA's MètaClinic is a professional electronic health record to manage
                          clinical data of patients with chronic conditions like diabetes
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_meteda" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-33 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_micare" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/micare-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">MiCare Health</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Personalizing peace of mind beyond the office visit.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_micare" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-34 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_mozzaz" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/mozzaz-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Mozzaz</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Mozzaz is a versatile digital health platform that drives actionable insights and personalized care experiences for both patients and providers. 
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_mozzaz" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-35 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-155 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="155"><img id="icon_my_diabby" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/my_diabby-02.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">myDiabby</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          The telemedicine app for diabetes : A mobile app for patients to collect all data from BGMs, CGMs, insulin pens and pumps plus  HCP online software to perform telemedicine and manage patients.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_my_diabby" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-155 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="155">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-36 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-108 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="108"><img id="icon_one_drop" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/onedrop-iconcircle.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">One Drop</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>Discover how AI and the human touch of health coaching can help you manage diabetes, heart health, and weight.</p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_one_drop" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-108 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="108">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-37 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_one_two_diabetes" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/one_two_diabetes-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">OneTwo Diabetes</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          OneTwo Diabetes interprets all your glucose data and provides easy to understand reports for type 1 or type 2 diabetes treatment.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_one_two_diabetes" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-38 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-109 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="109"><img id="icon_rimidi" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/logo.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Rimidi</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Rimidi's diabetes management platform turns population health into
                          personalized management that is effective and efficient.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_rimidi" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-109 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="109">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-39 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-150 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="150"><img id="icon_seqster" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/seqster_120x120.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Seqster</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Integrate all your health, DNA, fitness, and medical device data in one
                          place, visualized to better manage your health.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_seqster" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-150 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="150">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-40 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_snaq" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/snaq-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">SNAQ</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Snap a photo to count the carb content of your meals. Connect your glucose sensor and learn how meals affect your Time-In-Range.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_snaq" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-41 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-146 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="146"><img id="icon_social_diabetes" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/social_diabetes-02.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">SocialDiabetes</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          A self-management mobile APP and a medical platform for remote patient monitoring to deliver personalized care and optimized outcomes
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_social_diabetes" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-146 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="146">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-42 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-151 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="151"><img id="icon_steady_health" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/rectangular_single_letter.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Steady Health</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>Steady Health provides technology-enabled modern care for diabetes.</p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_steady_health" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-151 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="151">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-43 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_sugarmate" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/sugarmate-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Sugarmate</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          The Sugarmate™ app uses CGM data to create a wide range of visualization tools that can streamline your diabetes management.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_sugarmate" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-44 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_thorough_care" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/thorough_care-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">ThoroughCare</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          ThoroughCare's integration with Dexcom enables Remote Patient Monitoring of patient vitals within a single care coordination solution.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_thorough_care" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-45 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-110 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="110"><img id="icon_tidepool" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/tidepool-logo-120.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Tidepool</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>Free software combining pump, BGM, CGM in one place.</p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_tidepool" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-110 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="110">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-46 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-153 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="153"><img id="icon_under_my_fork" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/umf_logo_512_0.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Undermyfork</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Undermyfork overlays your Dexcom CGM data over photos of your meals, then
                          classifies your meals by postprandial time-in-range.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_under_my_fork" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-153 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="153">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-47 views-row-odd views-row-last">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-145 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="145"><img id="icon_validic" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/validic_circular-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Validic</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Validic's advanced data connectivity platform removes the burdens
                          associated with data access, integration, standardization, and storage.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_validic" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-145 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="145">Learn More</a></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="view-footer">
                <p *ngIf="!enableUiRebranding" text-align="center">
                  <em>These apps are made available by third parties independent of Dexcom and
                    inclusion in this Gallery is not approval or endorsement by Dexcom of these
                    third-party apps.</em>
                </p>
                <p *ngIf="enableUiRebranding" class="default-font p-font" style="margin-top: 47px;">
                  Authorized Digital Health Partner apps and devices are independent of Dexcom and availability to Dexcom users and HCPs is not approval or endorsement by Dexcom of these third-party apps and devices.
                </p>
              </div>
            </div>
          </section>
          <!-- /.block -->
        </div>
      </section>
    </div>
  </div>
</div>
<div *ngIf="enableUiRebranding" id="gallery-disclaimer-outer-container">
  <div id="gallery-disclaimer-inner-container">
    <p id="gallery-disclaimer-data-on-file-header" class="default-font"><sup>*</sup>Dexcom users must have an active internet connection and provide consent to share their Dexcom CGM data with a partner application.</p>
    <p id="gallery-safety-statement" class="default-font">
      BRIEF SAFETY STATEMENT: Failure to use the Dexcom Continuous Glucose Monitoring System and its components according to the instructions for use provided with your device and available at https://www.dexcom.com/safety-information and to properly consider all indications, contraindications, warnings, precautions, and cautions in those instructions for use may result in you missing a severe hypoglycemia (low blood glucose) or hyperglycemia (high blood glucose) occurrence and/or making a treatment decision that may result in injury. If your glucose alerts and readings from the Dexcom CGM do not match symptoms, use a blood glucose meter to make diabetes treatment decisions. Seek medical advice and attention when appropriate, including for any medical emergency.
    </p>
  </div>
</div>

<div id="jquery_ajax_load" class="modal fade in prepare-inner-modal-processed" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="false" (click)="toggleAppModal($event)">
  <div class="ctools-modal-dialog modal-dialog node-type-app">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" id="close_modal_button" class="close" data-dismiss="modal" aria-hidden="true">
          ×
        </button>
      </div>
      <div class="modal-body">
        <div id="node-107" class="node node-app has-screenshots clearfix" about="/content/ensa"
          typeof="sioc:Item foaf:Document">
          <div class="field field-name-field-app-logo field-type-image field-label-hidden disable-logo-border">
            <div class="field-items">
              <div class="field-item even">
                <img typeof="foaf:Image" class="img-responsive" src="{{ currentPartner.icon }}" alt="" />
              </div>
            </div>
          </div>
          <h2 class="title">{{ currentPartner.displayName }}</h2>

          <div class="content">
            <div class="field field-name-body field-type-text-with-summary field-label-hidden">
              <div class="field-items">
                <div class="field-item even" property="content:encoded" style="white-space: pre-wrap">
                  {{ currentPartner.desc }}
                </div>
              </div>
            </div>
            <div class="field field-name-field-app-website-link field-type-link-field field-label-hidden">
              <div class="field-items">
                <div class="field-item even">
                  <a href="{{ currentPartner.url }}" target="_blank">{{ currentPartner.url }}</a>
                </div>
              </div>
            </div>
            <div class="field field-name-field-app-screen-shot field-type-image field-label-hidden">
              <div class="field-items">
                <div class="field-item even">
                  <a (click)="openFullImageModal(currentPartner.fullImages[0])"
                    class="twitter_bootstrap_modal_TB bs_modal tb-trigger-processed tb-modal-trigger-processed"
                    data-target="#jquery_ajax_load_inner" data-toggle="modal">
                    <img typeof="foaf:Image" class="img-responsive" src="{{ currentPartner.screenShot[0] }}"
                      alt="" /></a>
                </div>
                <div class="field-item odd">
                  <a (click)="openFullImageModal(currentPartner.fullImages[1])"
                    class="twitter_bootstrap_modal_TB bs_modal tb-trigger-processed tb-modal-trigger-processed"
                    data-target="#jquery_ajax_load_inner" data-toggle="modal">
                    <img typeof="foaf:Image" class="img-responsive" src="{{ currentPartner.screenShot[1] }}"
                      alt="" /></a>
                </div>
                <div class="field-item even">
                  <a (click)="openFullImageModal(currentPartner.fullImages[2])"
                    class="twitter_bootstrap_modal_TB bs_modal tb-trigger-processed tb-modal-trigger-processed"
                    data-target="#jquery_ajax_load_inner" data-toggle="modal">
                    <img typeof="foaf:Image" class="img-responsive" src="{{ currentPartner.screenShot[2] }}"
                      alt="" /></a>
                </div>
              </div>
            </div>
            <div class="field field-name-field-apple-app-store-link field-type-link-field field-label-hidden"
              *ngIf="currentPartner.appStoreLink.apple">
              <div class="field-items">
                <div class="field-item even">
                  <a href="{{ currentPartner.appStoreLink.apple }}" target="_blank">Apple App Store Link</a>
                </div>
              </div>
            </div>
            <div class="field field-name-field-google-play-store-link field-type-link-field field-label-hidden"
              *ngIf="currentPartner.appStoreLink.google">
              <div class="field-items">
                <div class="field-item even">
                  <a href="{{ currentPartner.appStoreLink.apple }}" target="_blank">Google Play Store Link</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="fullImageUrl" id="jquery_ajax_load_inner" class="modal modal-gallery-app-inner in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style="display: block;">
  <div class="ctools-modal-dialog modal-dialog node-type-app">
    <div class="modal-content">
      <div class="modal-header"><button type="button" (click)="closeFullImageModal()" class="close" data-dismiss="modal" aria-hidden="true">×</button></div>
      <div class="modal-body"><div id="file-131" class="file file-image file-image-png">
  
          <h2 class="element-invisible"><a href="/file/131">1bios-screenshot-1_dexcom.png</a></h2>
      
    
    <div class="content">
      <img typeof="foaf:Image" class="img-responsive" [src]="fullImageUrl" alt="">  </div>
  
    
  </div>
  </div>
    </div>
  </div>
  </div>
<div *ngIf="showBackdrop" class="modal-backdrop fade in"></div>