import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GalleryComponent } from './gallery/gallery.component';
import { AuthGuard } from './guard/auth.guard';
import { HomeComponent } from './home/home.component';
import { SandboxConsentComponent } from './sandbox/sandbox-consent/sandbox-consent.component';
import { SandboxErrorComponent } from './sandbox/sandbox-error/sandbox-error.component';
import { SandboxHomeComponent } from './sandbox/sandbox-home/sandbox-home.component';

const routes: Routes = [
  { path: 'sandbox-login', component: SandboxHomeComponent },
  { path: 'sandbox-consent', component: SandboxConsentComponent },
  { path: 'sandbox-error', component: SandboxErrorComponent },

  { path: 'home', component: HomeComponent },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then((m) => m.SupportModule),
  },
  { path: 'gallery', component: GalleryComponent },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
