import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

const iconClasses = ['toast-error', 'toast-info', 'toast-success', 'toast-warning'] as const;

const positionClasses = [
  'toast-top-right',
  'toast-top-center',
  'toast-top-left',
  'toast-center-right',
  'toast-center-center',
  'toast-center-left',
  'toast-bottom-right',
  'toast-bottom-center',
  'toast-bottom-left',
] as const;

const DEFAULT_ERROR_MESSAGE = 'There was a problem on our end, please try again later.';

type ToastType = typeof iconClasses[number];
type PositionClass = typeof positionClasses[number];

type ToastConfig = {
  type: ToastType;
  positionClass: PositionClass;
} & Partial<IndividualConfig>;

@Injectable({ providedIn: 'root' })
export class ToastService {
  defaultToastconfig: ToastConfig = {
    type: 'toast-success',
    positionClass: 'toast-bottom-center',
  };

  constructor(private toastr: ToastrService) {}

  show(message?: string, title?: string, config?: Partial<ToastConfig>) {
    const toastType = config?.type ?? this.defaultToastconfig.type;
    const toastConfig = { positionClass: this.defaultToastconfig.positionClass, ...config };
    delete toastConfig.type;
    return this.toastr.show(message, title, toastConfig, toastType);
  }

  info(message?: string, title?: string, config?: Partial<ToastConfig>) {
    return this.show(message, title, { ...config, type: 'toast-info' });
  }

  success(message?: string, title?: string, config?: Partial<ToastConfig>) {
    return this.show(message, title, { ...config, type: 'toast-success' });
  }

  warning(message?: string, title?: string, config?: Partial<ToastConfig>) {
    return this.show(message, title, { ...config, type: 'toast-warning' });
  }

  error(message?: string, title?: string, config?: Partial<ToastConfig>) {
    return this.show(message || DEFAULT_ERROR_MESSAGE, title, { ...config, type: 'toast-error' });
  }
}
