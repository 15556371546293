import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConsentParams } from '../sandbox/consentParams';
import { Request } from './request';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiURL = 'https://supportrequest.free.beeceptor.com/support';
  constructor(private httpClient: HttpClient) {}

  public createCustomer(request: Request) {
    return this.httpClient.post<Request>(this.apiURL, request);
  }

  public setConsents(consentBody: ConsentParams) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.httpClient.post<{ authCode: string }>('/consent', consentBody, options);
  }

  public getAppName(clientId: string) {
    return this.httpClient.get<{ appName: string }>('/client', {
      params: new HttpParams().set('clientId', clientId.toString()),
    });
  }
}
