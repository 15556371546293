import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  enableUiRebranding = environment.enableUiRebranding === 'true';

  constructor() {}

  ngOnInit(): void {}

  scrollDown(): void {
    const element = document.getElementById('block-dexcom-front-blocks-cta-2');
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
}
