<div class="page-content" *ngIf="appNameAvailable">
  <div class="main-container container">
    <div class="row">
      <div class="col-2"></div>
      <div class="col-8">
        <div class="header">
          <img src="../../../assets/image/logo-store-dexcom.png" alt="image" />
        </div>
      </div>
      <div class="col-2"></div>
    </div>

    <div class="row">
      <div class="col-2"></div>
      <div class="col-8">
        <h4><strong>Permission to Disclose and Authorization</strong></h4>
      </div>
      <div class="col-2"></div>
    </div>

    <div class="row">
      <div class="col-2"></div>
      <div class="col-8">
        <div class="style">
          <p>
            By signing below, you are confirming that you have subscribed for services provided by
            <strong>{{ appName }}</strong> and you desire to have certain of your Personal
            Information (as defined in Dexcom's Privacy Policy available here) sent to that third
            party by Dexcom. You hereby authorize and instruct Dexcom to electronically provide your
            Registration Information and Data Services Information (as defined in Dexcom’s Privacy
            Policy) to <strong>{{ appName }}</strong
            >. You further acknowledge and agree that Dexcom has no control over how your Personal
            Information is used by <strong>{{ appName }}</strong> and Dexcom shall not be liable for
            <strong>{{ appName }}'s</strong> receipt, use or redisclosure of your Personal
            Information or the accuracy of such Personal Information, and your Personal Information
            once transferred is no longer subject to Dexcom's Privacy Policy or any applicable
            protections under privacy laws applicable to Dexcom. You may revoke this authorization
            at any time at Dexcom.com by signing in to your account and changing your authorizations
            in Permissions, and we will promptly terminate providing further Personal Information to
            <strong>{{ appName }}</strong
            >, but any information previously provided will not be affected by this revocation. This
            authorization expires when you terminate your Dexcom account or you revoke it. This
            authorization replaces any previous authorizations you may have provided to us for
            <strong>{{ appName }}</strong
            >.
          </p>
          <p>
            Declining this authorization will not revoke any previous authorizations you may have
            provided to us for
            <strong>{{ appName }}</strong
            >. You may revoke previous authorizations at any time at Dexcom.com by signing in to
            your account and changing your authorizations in Permissions, and we will promptly
            terminate providing further Personal Information to <strong>{{ appName }}</strong
            >, but any information previously provided will not be affected by this revocation.
          </p>
          <p>
            The Personal Information that <strong>{{ appName }}</strong> is requesting access to
            includes:
          </p>

          <ul>
            <li>Your Estimated Blood Glucose Levels</li>
            <li>Your Calibration Data</li>
            <li>Your Device Details</li>
            <li>Your CGM Statistics</li>
            <li>Your Events Data</li>
          </ul>
        </div>

        <h6><strong>Enter electronic signature to authorize:</strong></h6>
        <div class="form-group">
          <input
            class="sizing"
            type="text"
            name="username"
            [ngModel]="username"
            #name="ngModel"
            maxlength="255"
            placeholder="Type full name here "
            required
          />
        </div>
        <h6 *ngIf="!name.invalid">
          Signed on:
          <span class="font-size-13px">{{ registerDate }}</span>
        </h6>
        <button
          class="btn"
          id="btnConsentAuthorize"
          name="button"
          [disabled]="name.invalid"
          (click)="authorize()"
        >
          Authorize
        </button>
        <button class="btn" id="btnConsentDecline" name="button" (click)="decline()">
          Decline
        </button>
      </div>
      <div class="col-2"></div>
    </div>

    <!--  <footer-->
    <!--    id="footer-wrapper"-->
    <!--    class="section section-footer ng-scope section-footer"-->
    <!--  >-->
    <!--    <div class="container-fluid" style="text-align: center;">-->
    <!--      <ul class="menu">-->
    <!--        <li class="leaf">-->
    <!--          <a-->
    <!--            href="https://www.dexcom.com/linked/documentservice/TermsOfUse/SW11093/2.4.0.15/en-US/US"-->
    <!--            target="_blank"-->
    <!--            >Terms of Use</a-->
    <!--          >-->
    <!--        </li>-->
    <!--        <li class="leaf">-->
    <!--          <a-->
    <!--            href="https://www.dexcom.com/linked/documentservice/PrivacyPolicy/SW11093/2.4.0.15/en-US/US"-->
    <!--            target="_blank"-->
    <!--            >Privacy Policy</a-->
    <!--          >-->
    <!--        </li>-->
    <!--      </ul>-->
    <!--      <div>-->
    <!--        <span class="label"-->
    <!--          >©2015 Dexcom, Inc. All rights reserved.<br />LBL013877 Rev003</span-->
    <!--        >-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </footer>-->
  </div>
</div>
