import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from '../../services/auth.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });
  submitted = false;
  errMsg = '';
  returnUrl = '';

  readonly HOME_ROUTE = '/home';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService,
    private appComponent: AppComponent
  ) {
}

  ngOnInit(): void {
    if (this.authService.isAuthenticated) {
      this.router.navigate(['./account/apps']);
      this.toastService.info('You are already logged in.')
    }

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  login(): void {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      this.authService.login(this.username.value, this.password.value).subscribe({
        next: () => {
          if (this.appComponent.previousUrl != '') this.router.navigate([`.${this.appComponent.previousUrl}`]);
          else this.router.navigate(['./account/apps']);

          this.appComponent.previousUrl = '';
        },
        error: (err: HttpErrorResponse) => {
          if (err.status == 401) {
            this.toastService.error('Username/email address or password were incorrect.');
          } else {
            this.toastService.error(
              'An unexpected error has occurred while trying to log in, please try again later.'
            );
          }
        },
      });
    }
  }

  get username(): AbstractControl {
    return this.loginForm.controls['username'];
  }
  get password(): AbstractControl {
    return this.loginForm.controls['password'];
  }
}
