import { Injectable } from '@angular/core';
import { ConsentParams } from './consentParams';

@Injectable({
  providedIn: 'root',
})
export class ConsentParamService {
  private consentbody: ConsentParams = new ConsentParams();

  setConsentBody(consentParam: ConsentParams) {
    this.consentbody = consentParam;
  }

  getConsentBody() {
    return this.consentbody;
  }
}
