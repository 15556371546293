<h1 class="page-title">Login</h1>
<div class="container">
  <section id="block-system-main" class="block block-system pull-right clearfix">
    <form
        [formGroup]="loginForm"
        (ngSubmit)="login()"
        novalidate="novalidate"
        method="post"
        id="user-login"
        accept-charset="UTF-8"
      >
        <div>
          <div class="form-item form-item-name form-type-textfield form-group">
            <label class="control-label" for="edit-name"
              >Username or Email
              <span class="form-required" title="This field is required.">*</span></label
            >
            <input
              [ngClass]="{ 'is-invalid': submitted && f['username'].errors }"
              formControlName="username"
              autocomplete="off"
              placeholder="name@example.com"
              class="form-control form-text required"
              title=""
              data-toggle="tooltip"
              type="text"
              id="edit-name"
              name="name"
              value=""
              size="60"
              maxlength="60"
              data-original-title="You may login with either your assigned username or your e-mail address."
            />
            <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
              <div *ngIf="f['username'].errors['required']">
                <span class="invalid-text">Username/Email is required</span>
              </div>
            </div>
          </div>
          <div class="form-item form-item-pass form-type-password form-group">
            <label class="control-label" for="edit-pass"
              >Password <span class="form-required" title="This field is required.">*</span></label
            >
            <input
              [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
              formControlName="password"
              autocomplete="off"
              placeholder="Password"
              class="form-control form-text required"
              title=""
              data-toggle="tooltip"
              type="password"
              id="edit-pass"
              name="pass"
              size="60"
              maxlength="128"
              data-original-title="The password field is case sensitive."
            />
            <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
              <div *ngIf="f['password'].errors['required']">
                <span class="invalid-text">Password is required</span>
              </div>
            </div>
          </div>
          <input
            type="hidden"
            name="form_build_id"
            value="form-isrtIJK6ZgQHOVYkoS2WlXfoNsnTRv5cznX6y1T8BOI"
          />
          <input type="hidden" name="form_id" value="user_login" />
          <div class="form-actions form-wrapper form-group" id="edit-actions">
            <button
              type="submit"
              id="edit-submit"
              name="op"
              value="Log In"
              class="btn btn-primary form-submit icon-before"
            >
              <span class="icon" aria-hidden="true"></span>Log In
            </button>
          </div>
          <div class="ddp-pass">
            <a  href="/user/forgot-password" >I forgot my Password</a>
          </div>
          <div class="ddp-readme">
            developer.dexcom uses a separate account from the Dexcom store.
          </div>
          <div class="ddp-account">
            Need a developer.dexcom account?
            <a href="/user/register">Create an Account</a>
          </div>
        </div>
    </form>
  </section>
</div>

