import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-nav-bar-auth-button',
  templateUrl: './nav-bar-auth-button.component.html',
  styleUrls: ['./nav-bar-auth-button.component.css'],
})
export class NavBarAuthButtonComponent {
  enableUiRebranding = environment.enableUiRebranding === 'true';

  constructor(public authService: AuthService) {}

  toggleUserInfo(): void {
    const dialog = document.getElementById('user') as HTMLInputElement;
    if (dialog.classList.contains('open')) {
      dialog.classList.remove('open');
    } else {
      dialog.classList.add('open');
    }
  }

  logout() {
    this.authService.logout().subscribe();
    this.toggleUserInfo();
  }
}
