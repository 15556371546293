<div class="page-content">
  <div class="main-container container">
    <div class="row">
      <div class="col"></div>

      <div class="col-6">
        <a href="#">
          <img src="../../../assets/image/logo-store-dexcom.png" />
        </a>
        <p class="style">The client application is not known or is not authorized.</p>
      </div>

      <div class="col"></div>
    </div>
  </div>

  <!--  <footer-->
  <!--    id="footer-wrapper"-->
  <!--    class="section section-footer ng-scope section-footer"-->
  <!--  >-->
  <!--    <div class="container-fluid" style="text-align: center;">-->
  <!--      <ul class="menu">-->
  <!--        <li class="leaf">-->
  <!--          <a-->
  <!--            href="https://www.dexcom.com/linked/documentservice/TermsOfUse/SW11093/2.4.0.15/en-US/US"-->
  <!--            target="_blank"-->
  <!--            >Terms of Use</a-->
  <!--          >-->
  <!--        </li>-->
  <!--        <li class="leaf">-->
  <!--          <a-->
  <!--            href="https://www.dexcom.com/linked/documentservice/PrivacyPolicy/SW11093/2.4.0.15/en-US/US"-->
  <!--            target="_blank"-->
  <!--            >Privacy Policy</a-->
  <!--          >-->
  <!--        </li>-->
  <!--      </ul>-->
  <!--      <div>-->
  <!--        <span class="label"-->
  <!--          >©2015 Dexcom, Inc. All rights reserved.<br />LBL013877 Rev003</span-->
  <!--        >-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </footer>-->
</div>
