import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'developer-portal-ui';
  isHomeScreen = true;

  previousUrl = '';

  private readonly invalidUrlList: Array<string> = environment.invalidLogBackInUrls.split(',');

  constructor(
    private router: Router,
    private swUpdate: SwUpdate,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (this.isValidUrl(event.url)) this.previousUrl = event.url;
      }
    });

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe(() => {
        // if (confirm("New version available. Load New Version?")) {

        window.location.reload();
        // }
      });
    }

    this.router.events.subscribe(() => {
      this.isHomeScreen = this.router.url === '/home' || this.router.url === '/';
    });
    this.authService.autoLogin();
  }

  onActivate() {
    window.scroll(0, 0);
  }

  private isValidUrl(url: string): boolean {
    let isValid = true;
    this.invalidUrlList.forEach((invalidUrl) => {
      if (url.includes(invalidUrl) || url == '/') {
        isValid = false;
        if (url == '/home' || url == '/') this.previousUrl = '';
        return;
      }
    });

    return isValid;
  }
}
