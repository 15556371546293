import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { ConsentParams } from '../consentParams';
import { ConsentParamService } from '../consentParamService';

@Component({
  selector: 'app-sandbox-home',
  templateUrl: './sandbox-home.component.html',
  styleUrls: ['./sandbox-home.component.css'],
})
export class SandboxHomeComponent implements OnInit {
  selectedUser = '';
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private service: ApiService,
    private consentParamService: ConsentParamService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['error']) {
        this.router.navigate(['/sandbox-error']);
      }
    });
  }

  onSubmit() {
    this.route.queryParams.subscribe((params) => {
      const clientId = params['client_id'];
      const redirectURIs = params['redirect_uri'];

      let state = '';
      if (params['state']) {
        state = params['state'];
      }
      const consentBody: ConsentParams = new ConsentParams(
        this.selectedUser,
        clientId,
        redirectURIs,
        state
      );
      this.consentParamService.setConsentBody(consentBody);
    });
    if (this.selectedUser != '') {
      this.router.navigate(['/sandbox-consent']);
    }
  }
}
