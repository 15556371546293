<ul class="menu nav navbar-nav account-menu" [ngClass]="{'rebranded-menu': enableUiRebranding}">
  <li class="login-link-wrapper dropdown" id="user">
    <a *ngIf="!authService.isAuthenticated" routerLinkActive="active" routerLink="user/login" class="login-link"
      >Log In</a
    >
    <a
      *ngIf="authService.isAuthenticated"
      (click)="toggleUserInfo()"
      class="btn login-link dropdown-toggle"
      data-toggle="dropdown"
      role="button"
    >
      <span class="user-icon"></span>
      <span class="user-name">{{ authService.username }}</span>
      <span class="caret"></span>
    </a>
    <ul class="dropdown-menu">
      <li class="dropdown-item">
        <a routerLink="/account/settings" (click)="toggleUserInfo()">Manage Account</a>
      </li>
      <li class="dropdown-item">
        <a routerLink="/account/apps" (click)="toggleUserInfo()">My Apps</a>
      </li>
      <li *ngIf="authService.isDexcomAdmin" class="dropdown-item">
        <a (click)="toggleUserInfo()" routerLink="/admin/users">Users</a>
      </li>
      <li class="dropdown-item"><a (click)="logout()">Log Out</a></li>
    </ul>
  </li>
</ul>
